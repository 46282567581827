import { computed, ComputedRef } from 'vue'
import { EditorModule } from '@/store/modules/editor'
import { CourseModule } from '@/store/modules/course'
import CourseApi from '@/services/api/CourseApi'
import { UserModule } from '@/store/modules/user'
import { marked } from 'marked'
import {
  CourseModule as CourseModuleState,
  CourseSection,
  EditorCourseSection,
  EditorLessonAudioFile,
  EditorSlide,
  FollowUp,
  Lesson,
  LessonQuestion,
} from '@/services/interfaces/Course'
import { v4 as uuidv4 } from 'uuid'
import { Nullable } from '@/services/interfaces/Content'
import * as Sentry from '@sentry/vue'
import eventBus from '@/main'
import useI18n from '@/composables/useI18n'

marked.use({
  breaks: true,
})

interface useEditorReturn {
  currentLessonQuestions: ComputedRef<Nullable<LessonQuestion[]>>
  lessonSlideshows: ComputedRef<Nullable<EditorSlide[]>>
  lessonAudioFiles: ComputedRef<Nullable<EditorLessonAudioFile[]>>
  courseSections: ComputedRef<Nullable<EditorCourseSection[]>>
  currentEditorModule: ComputedRef<Nullable<CourseModuleState>>
  currentEditorLesson: ComputedRef<Nullable<Lesson>>
  hasLocalChanges: ComputedRef<(a: any, b: any) => boolean>
  hasChanges: ComputedRef<boolean>
  inEditorMode: ComputedRef<boolean>
  isNewLesson: ComputedRef<boolean>
  isNewModule: ComputedRef<boolean>
  isUndoingOrRedoing: ComputedRef<boolean>
  isDiscardingAllChanges: ComputedRef<boolean>
  discardChanges: (okCallback?: () => void) => void
  discardAllChanges: () => void
  saveAllChanges: () => void
  decodeAndParseSource: (source: string) => string
  currentQuizQuestions: ComputedRef<Nullable<LessonQuestion[]>>
  followUp: ComputedRef<Nullable<FollowUp>>
  deleteFollowup: (lessonName: string, followupId?: string) => void
}

export default function useEditor(): useEditorReturn {
  const { translateString } = useI18n()
  const currentCourseId = computed(() => eventBus.$route.params.courseId)
  const moduleId = computed(() => eventBus.$route.params.moduleId)
  const lessonId = computed(() => eventBus.$route.params.lessonId)
  const inModulePage = computed(() => eventBus.$route.name === 'CourseModule')
  const inLessonPage = computed(() => eventBus.$route.name === 'Lesson')
  const inTrainingsPage = computed(() => eventBus.$route.name === 'Trainings')
  const isUndoingOrRedoing = computed(() => EditorModule.undoingOrRedoing)
  const isDiscardingAllChanges = computed(() => EditorModule.discardingAllChanges)

  const hasLocalChanges = computed(() => {
    return (a: any, b: any): boolean => {
      return JSON.stringify(a) !== JSON.stringify(b)
    }
  })

  const changedDataIsSet = computed(() => {
    if (inTrainingsPage.value) return !!(CourseModule.courseSections && courseSections.value)
    if (inModulePage.value) return !!(CourseModule.currentCourseModule && currentEditorModule.value)

    const questionsAreSet =
      CourseModule.currentLesson?.type === 'Quiz'
        ? CourseModule.quizQuestions && EditorModule.quizQuestions
        : CourseModule.currentLessonQuestions && EditorModule.currentLessonQuestions

    return !!(
      CourseModule.currentCourseModule &&
      currentEditorModule.value &&
      CourseModule.currentLesson &&
      EditorModule.currentLesson &&
      questionsAreSet
    )
  })

  const entityHasLocalChanges = computed(() => {
    if (inTrainingsPage.value)
      return hasLocalChanges.value(actualEditorCourseSections.value, CourseModule.courseSections)
    if (inModulePage.value) return hasLocalChanges.value(currentEditorModule.value, CourseModule.currentCourseModule)

    return (
      hasLocalChanges.value(EditorModule.currentLesson, CourseModule.currentLesson) ||
      hasLocalChanges.value(editorActualQuestions.value, CourseModule.currentLessonQuestions) ||
      hasLocalChanges.value(currentQuizQuestions.value, CourseModule.quizQuestions) ||
      hasLocalChanges.value(actualEditorLessonSlideshows.value, CourseModule.currentLesson?.slideShow ?? null) ||
      hasLocalChanges.value(EditorModule?.lessonLeuckentext, CourseModule.currentLesson?.leuckentext ?? null) ||
      hasLocalChanges.value(actualLessonAudioFiles.value, CourseModule.currentLesson?.audioFiles ?? null) ||
      hasLocalChanges.value(EditorModule.lessonDownloadable, CourseModule.currentLesson?.downloadables ?? null)
    )
  })

  const hasChanges = computed(() => {
    return changedDataIsSet.value && entityHasLocalChanges.value
  })

  const inEditorMode = computed(() => EditorModule.editMode)

  const actualEditorCourseSections = computed(() => {
    if (!courseSections.value) return null
    return courseSections.value.map((section) => ({
      ...section,
      editorId: undefined,
    }))
  })

  const actualEditorLessonSlideshows = computed(() => {
    if (!lessonSlideshows.value) return null
    const mappedLessonSlideshows = lessonSlideshows.value.map((slide) => ({
      ...slide,
      editorId: undefined,
      slideImageData: undefined,
    }))
    return mappedLessonSlideshows.filter((slide) => {
      return (
        !!(
          slide.title ||
          slide.imageUrl ||
          slide.slideImageData ||
          slide.videoUrl ||
          slide.videoTranscript ||
          slide.credits ||
          slide.audioUrl ||
          slide.audioAuthor
        ) && slide._destroy !== true
      )
    })
  })

  const actualLessonAudioFiles = computed(() => {
    if (!lessonAudioFiles.value) return null
    if (!lessonAudioFiles.value.length) return null
    return lessonAudioFiles.value.map((audioFile) => ({
      ...audioFile,
      editorId: undefined,
    }))
  })

  const editorActualQuestions = computed(() => {
    const mappedQuestions = EditorModule.currentLessonQuestions?.map((q) => ({
      ...q,
      answers: q.answers.filter((a) => (a.correct || a.details) && a._destroy !== true),
    }))
    return mappedQuestions?.filter((q) => !!(q.title || q.questionType || q.comment) && q._destroy !== true)
  })

  const discardAllChanges = () => {
    discardChanges(() => {
      EditorModule.executeAllSubscriptions('discard')
      EditorModule.setCurrentLessonQuestions(CourseModule.currentLessonQuestions)
      EditorModule.setCurrentModule(CourseModule.currentModule(currentCourseId.value, moduleId.value))
      if (CourseModule.currentLesson?.slideShow) {
        EditorModule.setLessonSlideshows(CourseModule.currentLesson.slideShow).then(() => {
          lessonSlideshows.value?.forEach((slide) => {
            eventBus.$set(slide, 'editorId', uuidv4())
            eventBus.$set(slide, 'slideImageData', '')
          })
        })
      }
      if (CourseModule.currentLesson?.quiz?.hasQuestions) {
        EditorModule.setQuizQuestions(CourseModule.originalQuizQuestions)
      }
      EditorModule.setLeuckentext(CourseModule.currentLesson?.leuckentext ?? null)
      EditorModule.setLeuckentextDeleteParams(null)
      EditorModule.setCurrentLessonAudioFiles(CourseModule.currentLesson?.audioFiles ?? null).then(() => {
        lessonAudioFiles.value?.forEach((audioFiles) => {
          eventBus.$set(audioFiles, 'editorId', uuidv4())
        })
      })
      EditorModule.setLessonDownloadable(CourseModule.currentLesson?.downloadables ?? null)
      if (CourseModule.courseSections) {
        EditorModule.setCourseSections(CourseModule.courseSections).then(() => {
          courseSections.value?.forEach((section) => {
            eventBus.$set(section, 'editorId', uuidv4())
          })
        })
      }
    })
  }

  const discardChanges = (okCallback?: () => void) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'ConfirmationModal',
      modalTitle: translateString('editor.discardingChanges'),
      modalProps: {
        confirmationText: translateString('editor.discardingChangesDesc'),
        cancelButtonText: translateString('editor.noDontDiscard'),
        okButtonText: translateString('editor.yesDiscard'),
        showSvgs: true,
        okCallback: (closeCallback: () => void) => {
          if (typeof okCallback === 'function') okCallback()
          if (typeof closeCallback === 'function') closeCallback()
        },
      },
      cssClass: 'confirmation editor',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const handleLessonQuestionsRequest = async (_lessonId?: string) => {
    return Promise.all(
      currentLessonQuestions.value?.map(async (question) => {
        const courseQuestion = CourseModule.currentLessonQuestions?.find((q) => q.id === question.id)
        const answers = await Promise.all(
          question.answers.map(async (answer) => {
            const courseAnswer = courseQuestion?.answers.find((a) => a.id === answer.id)

            if (answer._destroy) await CourseApi.deleteQuestionAnswer(answer.id!)
            else if (question.id && hasLocalChanges.value(answer, courseAnswer)) {
              answer.questionId = question.id
              const answerPayload = Object.assign({}, answer, { editorAnswerId: undefined })

              try {
                let response

                if (answer.id) {
                  response = await CourseApi.updateQuestionAnswer(answerPayload)
                } else {
                  response = await CourseApi.createQuestionAnswer(answerPayload)
                  answer.id = response.id
                }
              } catch (error: any) {
                eventBus.$toasted.error(translateString('editor.messages.invalidAnswerPayload'))
              }
            }
            return answer
          }),
        )
        question.answers = answers
          .filter((answer) => !answer._destroy)
          .map((answer) => ({
            ...answer,
            editorAnswerId: undefined,
          }))

        if (question._destroy) await CourseApi.deleteQuestion(question.id!)
        else if (hasLocalChanges.value(question, courseQuestion)) {
          question.lessonId = _lessonId || lessonId.value

          if (question.id)
            await CourseApi.updateQuestion(Object.assign({}, question, { answers: undefined, editorId: undefined }))
          else {
            const data = await CourseApi.createQuestion(Object.assign({}, question, { editorId: undefined }))
            question.id = data.id
          }
        }
      }) || [],
    )
  }

  const handleLeuckentextRequest = async (_lessonId?: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const { lessonLeuckentext, leuckentextDeleteParams } = EditorModule
      ;[1].forEach(async () => {
        if (leuckentextDeleteParams) {
          await CourseApi.deleteLeuckentext(leuckentextDeleteParams.id, leuckentextDeleteParams.lessonId)
          EditorModule.setLeuckentextDeleteParams(null)
          eventBus.$toasted.success(translateString('editor.messages.leuckentextDeletedSuccessfully'))
        }
        const isValid = !!(
          lessonLeuckentext?.title &&
          lessonLeuckentext?.leuckentextItems?.every((item) => {
            const decodedItemText = decodeAndParseSource(item.text) as string
            const textMatch = decodedItemText.match(/{{.*}}/)
            return item.orderNumber && item.text && !!textMatch?.[0]
          })
        )
        if (!isValid && lessonLeuckentext) {
          eventBus.$toasted.error(translateString('editor.messages.invalidLeuckentextParams'))
          return resolve(false)
        }
        if (lessonLeuckentext && hasLocalChanges.value(lessonLeuckentext, CourseModule.currentLesson?.leuckentext)) {
          const leuckentextItems = lessonLeuckentext.leuckentextItems.map((item) => ({
            ...item,
            editorId: undefined,
          }))

          if (lessonLeuckentext.id) {
            // edit
            lessonLeuckentext.lessonId = _lessonId || lessonId.value
            await CourseApi.updateLeuckentext(Object.assign({}, lessonLeuckentext, { leuckentextItems }))
            eventBus.$toasted.success(translateString('editor.messages.leuckentextUpdatedSuccessfully'))
          } else {
            // create
            await CourseApi.createLeuckentext(
              Object.assign({}, lessonLeuckentext, { editorId: undefined, leuckentextItems }),
            )
            eventBus.$toasted.success(translateString('editor.messages.leuckentextCreatedSuccessfully'))
          }
        }
        resolve(true)
      })
    })
  }

  const isValidLessonPayload = (payload: any): boolean => {
    const { lesson } = payload

    const isDurationValid = 'duration' in lesson ? !!lesson.duration : true
    if (lesson.lessonType === 'SlideShow') {
      const valid = lesson.slideShow?.slides?.every((slide: EditorSlide) => {
        const actualSlide = CourseModule.currentLesson?.slideShow!.find((s) => slide.id === s.id)
        const imageChanged = hasLocalChanges.value(slide.imageUrl, actualSlide?.imageUrl)
        const videoChanged = hasLocalChanges.value(slide.videoUrl, actualSlide?.videoUrl)

        return isNewLesson.value
          ? !!(slide.videoUrl || slide.imageUrl || slide.slideImageData)
          : imageChanged || videoChanged
            ? !!(slide.videoUrl || slide.slideImageData)
            : true
      })
      if (isDurationValid && valid) return true
      eventBus.$toasted.error(translateString('editor.messages.invalidImageOrVideo'))
    } else if (lesson.lessonType === 'Text') {
      const valid = !!lesson.text?.content || !!EditorModule.currentLesson?.content
      if (isDurationValid && valid) return true
      eventBus.$toasted.error(translateString('editor.messages.invalidLessonContent'))
    } else if (lesson.lessonType === 'Video') {
      const hasVideo = !!lesson.video?.videoUrl || !!EditorModule.currentLesson?.videoUrl
      const hasTranscript = !!lesson.video?.videoTranscript || !!EditorModule.currentLesson?.videoTranscript
      if (hasTranscript && !hasVideo) {
        eventBus.$toasted.error(translateString('editor.messages.invalidVideoTranscript'))
        return false
      } else if (isDurationValid && hasVideo) return true
      eventBus.$toasted.error(translateString('editor.messages.invalidVideo'))
    } else if (lesson.lessonType === 'Downloadable') {
      return validateDownloadableLessonData({
        downloadTeaserData: lesson.downloadable.downloadTeaserData,
        realDownloadData: lesson.downloadable.realDownloadData,
      })
    } else {
      return true
    }

    return false
  }

  const validateDownloadableLessonData = (downloadable: { downloadTeaserData: string; realDownloadData?: string }) => {
    const hadDownloadTeaserImageUrl = !!EditorModule.currentLesson?.downloadables?.downloadTeaserImageUrl
    const hadDownloadUrl = !!EditorModule.currentLesson?.downloadables?.downloadUrl

    const validTeaserData =
      isNewLesson.value || hadDownloadTeaserImageUrl
        ? !!(downloadable.downloadTeaserData || EditorModule.lessonDownloadable?.downloadTeaserImageUrl)
        : true

    const validDownloadData =
      isNewLesson.value || hadDownloadUrl
        ? !!(downloadable.realDownloadData || EditorModule.lessonDownloadable?.downloadUrl)
        : true

    if (validTeaserData && validDownloadData) return true

    if (!validTeaserData) {
      eventBus.$toasted.error(translateString('editor.messages.invalidDownloadableTeaserFile'))
    } else if (!validDownloadData) {
      eventBus.$toasted.error(translateString('editor.messages.invalidDownloadableFile'))
    }
    return false
  }

  const passesQuestionValidation = (payload: any) => {
    if (payload.lesson.lessonType === 'Quiz') {
      return !!currentQuizQuestions.value?.every((q: LessonQuestion) => {
        return (
          q._destroy ||
          (q.title && q.answers.some((a) => a.correct && !a._destroy) && q.answers.every((a) => a.details))
        )
      })
    }

    return !!currentLessonQuestions.value?.every((q: LessonQuestion) => {
      return (
        q._destroy || (q.title && q.answers.some((a) => a.correct && !a._destroy) && q.answers.every((a) => a.details))
      )
    })
  }

  const passedAudioFilesValidation = (payload: any) => {
    if (payload.lesson.lessonType === 'Audio') {
      return !!lessonAudioFiles.value?.every((audioFile) => {
        return audioFile._destroy || (audioFile.title && audioFile.audioUrl && audioFile.audioAuthor)
      })
    }
    return true
  }

  const saveAllChanges = async () => {
    if (inLessonPage.value) {
      const payload: any = {
        lesson: {
          lessonType: EditorModule.currentLesson!.type,
        },
      }
      const properties = [
        'introText',
        'outroText',
        'duration',
        'content',
        'title',
        'videoUrl',
        'downloadables',
        'videoTranscript',
        'thumbnailTimestamp',
        'slideShow',
        'audioFiles',
        'profileImageUrl',
      ] as (keyof Lesson)[]
      properties.forEach((prop) => {
        if (EditorModule.currentLesson?.[prop] !== CourseModule.currentLesson?.[prop] || isNewLesson.value) {
          if ((prop === 'content' || prop === 'profileImageUrl') && EditorModule.currentLesson!.content) {
            const contentData: { id: string; content: string; lessonProfileImageData?: string } = {
              id: EditorModule.currentLesson!.lessonTypeId,
              content: EditorModule.currentLesson!.content,
            }

            if (['Text', 'Table', 'Script', 'Profile'].includes(payload.lesson.lessonType)) {
              if (EditorModule.lessonProfileImageData) {
                contentData.lessonProfileImageData = EditorModule.lessonProfileImageData
              }

              payload.lesson[`${payload.lesson.lessonType}`.toLowerCase()] = contentData
            }
            return
          }

          if (prop === 'videoUrl' && EditorModule.currentLesson!.videoUrl) {
            payload.lesson.video = {
              id: EditorModule.currentLesson!.lessonTypeId,
              videoUrl: EditorModule.currentLesson!.videoUrl,
            }
            return
          }

          if (prop === 'downloadables') {
            payload.lesson.downloadable = {
              id: EditorModule.currentLesson!.lessonTypeId,
              realDownloadData: EditorModule.lessonDownloadable?.realDownloadData,
              downloadTeaserData: EditorModule.downloadTeaserImageData,
              audioUrl: EditorModule.lessonDownloadable?.audioUrl,
              audioAuthorId: EditorModule.lessonDownloadable?.audioAuthor?.id ?? null,
            }
            return
          }

          if (prop === 'videoTranscript' && EditorModule.currentLesson!.videoUrl) {
            payload.lesson.video = {
              ...payload.lesson.video,
              videoUrl: EditorModule.currentLesson!.videoUrl,
              videoTranscript: EditorModule.currentLesson!.videoTranscript,
            }
            return
          }

          if (
            prop === 'thumbnailTimestamp' &&
            EditorModule.currentLesson!.videoUrl &&
            EditorModule.currentLesson!.thumbnailTimestamp
          ) {
            payload.lesson.video = {
              ...payload.lesson.video,
              videoUrl: EditorModule.currentLesson!.videoUrl,
              thumbnailTimestamp: EditorModule.currentLesson!.thumbnailTimestamp,
            }
            return
          }

          if (prop === 'slideShow' && EditorModule.lessonSlideshows) {
            /*
             we have to explicitly set either the videoUrl or imageUrl
             and we have to return the actual value which will also be needed for validation of the lesson
             */
            payload.lesson.slideShow = {
              id: EditorModule.currentLesson!.lessonTypeId,
              isOnePager: EditorModule.currentLesson!.isOnePager,
              slides: EditorModule.lessonSlideshows?.map((slide) => ({
                ...slide,
                id: slide.id.startsWith('new-') ? null : slide.id,
                editorId: undefined,
                audioAuthor: undefined,
                audioAuthorId: slide.audioAuthor?.id ?? null,
                slideImageData: slide.slideImageData ? slide.slideImageData : null,
                videoUrl: slide.slideImageData ? null : slide.videoUrl ? slide.videoUrl : null,
                imageUrl: slide.videoUrl ? null : slide.slideImageData ? null : slide.imageUrl ? slide.imageUrl : null,
              })),
            }
            return
          }

          if (prop === 'audioFiles' && EditorModule.currentLessonAudioFiles) {
            payload.lesson.audio = {
              id: EditorModule.currentLesson!.lessonTypeId,
              content: EditorModule.currentLesson!.content,
              audioFiles: EditorModule.currentLessonAudioFiles.map((audioFile) => ({
                ...audioFile,
                url: audioFile.audioUrl,
                authorId: audioFile.audioAuthor?.id,
                orderingNumber: audioFile.orderingNumber,
                editorId: undefined,
                audioUrl: undefined,
                audioAuthor: undefined,
              })),
            }
            return
          }

          payload.lesson[prop.replace('Text', '')] = EditorModule.currentLesson?.[prop]
        }
      })

      if (payload.lesson.lessonType === 'Quiz') {
        payload.lesson.quiz = {
          id: EditorModule.currentLesson!.lessonTypeId,
          questions: currentQuizQuestions.value?.map((question) => {
            return Object.assign({}, question, {
              editorId: undefined,
              answers: question.answers.map((answer) => Object.assign({}, answer, { editorAnswerId: undefined })),
            })
          }),
        }
      }

      if (!passesQuestionValidation(payload)) {
        eventBus.$toasted.error(translateString('editor.messages.invalidQuestionMessage'), { duration: 10000 })
        return
      }

      if (!passedAudioFilesValidation(payload)) {
        eventBus.$toasted.error(translateString('editor.messages.invalidAudioFilesMessage'), { duration: 10000 })
        return
      }

      if (!isValidLessonPayload(payload)) return

      if (isNewLesson.value) {
        return createLesson(payload)
      }
      return updateLesson(payload)
    } else if (inModulePage.value) {
      const payload: any = { module: {} }
      const properties = [
        'name',
        'isFree',
        'disabled',
        'description',
        'thumbnailTimestamp',
        'credits',
        'learnings',
        'benefits',
        'enableGuidedQuiz',
        'hasTiles',
        'authors',
        'teaserData',
        'teaserVideoUrl',
        'teaserImageUrl',
        'courseGroupId',
      ] as (keyof CourseModuleState)[]
      properties.forEach((prop) => {
        if (
          hasLocalChanges.value(
            EditorModule.currentModule?.[prop],
            CourseModule.currentModule(currentCourseId.value, moduleId.value)?.[prop],
          )
        ) {
          if (prop === 'isFree') {
            return (payload.module[prop.replace('isFree', 'free')] = EditorModule.currentModule?.isFree)
          }
          if (prop === 'teaserImageUrl' && EditorModule.moduleImageData) {
            return (payload.module.teaserData = EditorModule.moduleImageData)
          }
          payload.module[prop] = EditorModule.currentModule?.[prop]
        }

        // set courseGroupId of a new module
        if (prop === 'courseGroupId' && CourseModule.currentCourseGroupId) {
          return (payload.module[prop.replace('courseGroupId', 'courseGroupIds')] = [CourseModule.currentCourseGroupId])
        }

        payload.module.authors =
          EditorModule.currentModule?.authors?.map((author) => ({
            author_id: author.id,
          })) || []
      })
      if (isNewModule.value) {
        return createModule(payload)
      }
      return updateModule(payload)
    } else if (inTrainingsPage.value) {
      const properties = [
        'name',
        'description',
        'bulletPoints',
        'orderingNumber',
        'assignedCourses',
      ] as (keyof CourseSection)[]
      const promisedSectionUpdates: Promise<number>[] = []
      EditorModule.courseSections?.forEach((section, index) => {
        const payload: any = { courseSection: {} }
        properties.forEach((prop) => {
          if (hasLocalChanges.value(section[prop], CourseModule.courseSections?.[index]?.[prop])) {
            payload.courseSection[prop] = section[prop]
            if (prop === 'orderingNumber') {
              payload.courseSection[prop] = index + 1
            }
          }
        })
        if (Object.keys(payload.courseSection).length) {
          if (section.id) {
            promisedSectionUpdates.push(
              CourseApi.updateCourseSection(UserModule.currentCompany!.id, section.id, payload),
            )
          } else {
            payload.courseSection.assignedCourses = []
            promisedSectionUpdates.push(CourseApi.createCourseSection(UserModule.currentCompany!.id, payload))
          }
        }
      })
      await Promise.all(promisedSectionUpdates).then(async (resp) => {
        if (resp.every((status) => [200, 201].includes(status))) {
          eventBus.$emit('turn-on-loader')
          await CourseModule.getCourseSections(UserModule.currentCompany!.id)
          EditorModule.setCourseSections(CourseModule.courseSections).then(() => {
            courseSections.value?.forEach((section) => {
              eventBus.$set(section, 'editorId', uuidv4())
            })
          })
          eventBus.$emit('turn-off-loader')
          eventBus.$toasted.success(translateString('editor.messages.sectionUpdatedSuccessfully'))
        }
      })
    }
  }

  const lessonsCallback = async (isValidLeuckentext?: boolean, currentLessonId?: string) => {
    await CourseModule.getCourseModules(currentCourseId.value)

    const _lessonId = currentLessonId ? currentLessonId : lessonId.value

    const lesson = await CourseApi.getLesson(currentCourseId.value, moduleId.value, _lessonId)
    CourseModule.setCurrentLesson(lesson)
    EditorModule.setCurrentLesson(lesson)
    if (lesson.leuckentext && isValidLeuckentext) EditorModule.setLeuckentext(lesson.leuckentext)
    if (lesson.quiz?.hasQuestions) {
      CourseModule.setQuizQuestions(CourseModule.originalQuizQuestions)
      EditorModule.setQuizQuestions(CourseModule.originalQuizQuestions)
    }

    await CourseModule.getCurrentLessonQuestions({
      courseId: currentCourseId.value,
      moduleId: moduleId.value,
      lessonId: _lessonId,
    })
    EditorModule.setCurrentLessonQuestions(CourseModule.currentLessonQuestions)
    return
  }

  const createLesson = async (payload: any) => {
    return CourseApi.createLessonContent(currentCourseId.value, moduleId.value, payload)
      .then(async (lesson) => {
        eventBus.$toasted.success(translateString('editor.messages.lessonCreatedSuccessfully'))
        EditorModule.currentLesson!.id = lesson.lessonId
        const valid = await handleLeuckentextRequest(lesson.lessonId)
        await handleLessonQuestionsRequest(lesson.lessonId)

        eventBus.$emit('turn-on-loader')
        await lessonsCallback(valid, lesson.lessonId)
        eventBus.$emit('turn-off-loader')

        await eventBus.$router.replace({
          name: 'Lesson',
          params: { lessonId: lesson.lessonId },
        })
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          eventBus.$toasted.error(translateString('editor.messages.onlyEditorsAllowed'))
        }
      })
  }

  const updateLesson = async (payload: any) => {
    if (!(await handleLeuckentextRequest())) return
    await handleLessonQuestionsRequest()
    return CourseApi.updateLessonContent(currentCourseId.value, moduleId.value, lessonId.value, payload)
      .then(async () => {
        eventBus.$emit('turn-on-loader')
        await lessonsCallback(true)

        currentLessonQuestions.value?.forEach((q, i, questions) => {
          if (q._destroy) {
            questions.splice(i, 1)
          }
          q.answers.forEach((a, i, answers) => {
            if (a._destroy) {
              answers.splice(i, 1)
            }
          })
        })

        eventBus.$emit('turn-off-loader')
        eventBus.$toasted.success(translateString('editor.messages.lessonUpdatedSuccessfully'))
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          eventBus.$toasted.error(translateString('editor.messages.onlyEditorsAllowed'))
        }
      })
  }

  const createModule = async (payload: any) => {
    return CourseApi.createModule(currentCourseId.value, payload)
      .then(async (module) => {
        eventBus.$toasted.success(translateString('editor.messages.moduleCreatedSuccessfully'))
        EditorModule.currentModule!.id = module.moduleId
        eventBus.$emit('turn-on-loader')
        await CourseModule.getCourseModules(currentCourseId.value)
        eventBus.$emit('turn-off-loader')
        await eventBus.$router.replace({
          name: 'CourseModule',
          params: { moduleId: module.moduleId },
        })
      })
      .catch((e) => {
        if (e.response?.status === 403) {
          eventBus.$toasted.error(translateString('editor.messages.onlyEditorsAllowed'))
        }
      })
  }

  const updateModule = async (payload: any) => {
    return CourseApi.updateModuleContent(currentCourseId.value, moduleId.value, payload).then(async () => {
      eventBus.$emit('turn-on-loader')
      await CourseModule.getCourseModules(currentCourseId.value)

      const currentCourseModule = CourseModule.currentModule(currentCourseId.value, moduleId.value)
      CourseModule.setCurrentModule(currentCourseModule)
      EditorModule.setCurrentModule(structuredClone(currentCourseModule))

      eventBus.$emit('turn-off-loader')
      eventBus.$toasted.success(translateString('editor.messages.moduleUpdatedSuccessfully'))
    })
  }

  const sendDeleteRequest = (followupId?: string) => (callback: () => void) => {
    const deleteId = followupId ?? followUp.value?.id
    if (deleteId) {
      eventBus.$emit('turn-on-loader')
      CourseApi.deleteFollowup(deleteId)
        .then(async () => {
          if (followupId) {
            await CourseModule.getFollowups()
          }
          if (lessonId.value) {
            const lesson = await CourseApi.getLesson(currentCourseId.value, moduleId.value, lessonId.value)
            CourseModule.setCurrentLesson(lesson)
            EditorModule.setCurrentLesson(lesson)
          }
          eventBus.$toasted.success(translateString('editor.messages.followupDeletedSuccessfully'))
        })
        .catch((err) => {
          Sentry.captureException(err)
        })
        .finally(() => {
          eventBus.$emit('turn-off-loader')
          if (typeof callback === 'function') callback()
        })
    }
  }

  const deleteFollowup = (lessonName: string, followupId?: string) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'ConfirmationModal',
      modalTitle: eventBus.$t('editor.followup.confirmationTitle'),
      cssClass: 'confirmation',
      modalProps: {
        confirmationText: eventBus.$t('editor.followup.confirmationText', { lessonTitle: lessonName }),
        okCallback: sendDeleteRequest(followupId),
        okButtonText: eventBus.$t('editor.followup.yesDelete'),
        cancelButtonText: eventBus.$t('cancel'),
      },
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const currentLessonQuestions = computed(() => EditorModule.currentLessonQuestions)
  const currentQuizQuestions = computed(() => EditorModule.quizQuestions)
  const currentEditorModule = computed(() => EditorModule.currentModule)
  const courseSections = computed(() => EditorModule.courseSections)
  const currentEditorLesson = computed(() => EditorModule.currentLesson)
  const followUp = computed(() => EditorModule.currentLesson?.followUp || null)
  const lessonSlideshows = computed(() => EditorModule.lessonSlideshows)
  const lessonAudioFiles = computed(() => EditorModule.currentLessonAudioFiles)
  const isNewLesson = computed(() => !!lessonId.value?.startsWith('new-'))
  const isNewModule = computed(() => !!moduleId.value?.startsWith('mod-'))

  const decodeAndParseSource = (source: string) => {
    try {
      return marked.parse(decodeURIComponent(source)).toString()
    } catch (error) {
      return marked.parse(source).toString()
    }
  }

  return {
    hasLocalChanges,
    hasChanges,
    inEditorMode,
    isUndoingOrRedoing,
    isDiscardingAllChanges,
    discardChanges,
    discardAllChanges,
    currentLessonQuestions,
    lessonSlideshows,
    lessonAudioFiles,
    currentEditorLesson,
    saveAllChanges,
    isNewLesson,
    isNewModule,
    currentEditorModule,
    decodeAndParseSource,
    currentQuizQuestions,
    followUp,
    courseSections,
    deleteFollowup,
  }
}
