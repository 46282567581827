<template lang="pug">
.guided-quiz(:id='currentGuidedQuizCompletionId ? currentGuidedQuizCompletionId : ""')
    LoadingSpinner(v-if='loadingGuidedQuiz')
    template(v-else)
        NonFreeLesson(
          :current-lesson-title='currentModule.name',
          v-if='!(isAPurchasedCourse || currentModule.isFree)'
        )
        template(v-else)
            .header-info
                h2.module-name(v-if='currentModule && currentModule.name') {{ $t('courses.questionsFrom') }} "{{ currentModule.name }}"
                h5.description {{ $t('courses.guidedQuizSubtitle') }}
                GuidedQuizResults(
                    :points='points',
                    v-if='isMobileDevice'
                )
            .quiz-results(
                data-cy='guided-quiz-results',
                v-if='completedGuidedQuiz'
            )
                h3 {{ $t('courses.testCompleted') }}
                .quiz-results-wrapper
                    .buttons-results
                        .buttons
                            KetchUpButton.primary(
                                @click.native='redoWrongAnswers',
                                data-cy='redo-wrong-answers',
                                v-if='points.wrong > 0'
                            )
                                h5 {{ $t('courses.redoWrongAnswers') }}
                            KetchUpButton.tertiary(
                                @click.native='redoWholeTest',
                                data-cy='redo-whole-test'
                            )
                                h5 {{ $t('courses.redoWholeTest') }}
                        GuidedQuizResults(
                            :points='points',
                            :show-transition='false',
                            v-if='isDesktopDevice'
                        )
                    .quiz-results-completions(v-if='guidedQuizCompletions.length > 0')
                        .chart-labels
                            .chart-label-wrapper(
                                :key='index',
                                v-for='(label, index) in chartLabels'
                            )
                                .chart-label(v-if='label.show')
                                    .chart-color(:style='{ backgroundColor: label.color }')
                                    h5 {{ label.label }}
                        BarChart(
                            :chart-data='completionsChartData',
                            :max-y-axes='100',
                            :show-legend-label='false'
                        )
                .button-wrapper
                    KetchUpButton.tertiary(@click.native='nextLessonOrModule')
                        h5 {{ studyingStatus }}
                        SVGRenderer(
                            :has-hover='false',
                            :icon='arrowRight',
                            :stroke-color='"var(--primary-text-color)"'
                        )
            .not-started-quiz(v-else-if='!startedQuiz')
                KetchUpButton.primary(
                    @click.native='startedQuiz = !startedQuiz',
                    data-cy='start-guided-quiz'
                )
                    h5 {{ $t('courses.startQuiz') }}
            .quiz-content(
                data-cy='guided-quiz-content',
                v-else-if='currentQuestion'
            )
                QuestionWithButtons(
                    :current-question-number='questionIndex + 1',
                    :has-new-questions='hasNewQuestions',
                    :in-results-mode='calculatedPoints',
                    :is-guided-quiz='true',
                    :questions='[currentQuestion]',
                    :total-questions='totalQuestions',
                    @previous='previousQuestion',
                    @submit='nextQuestion'
                )
                    template(
                        #question-result,
                        v-if='isDesktopDevice'
                    )
                        .results-cta
                            GuidedQuizResults(:points='points')
                            KetchUpButton.tertiary(
                                @click.native='abortQuiz',
                                data-cy='abort-guided-quiz'
                            )
                                h5 {{ $t('quiz.questionQuizModal.abortButtonText') }}
</template>

<script setup lang="ts">
  import { computed, onMounted, reactive, ref, watch } from 'vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import useIcons from '@/composables/useIcons'
  import NonFreeLesson from '@/components/course/NonFreeLesson.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import CourseApi from '@/services/api/CourseApi'
  import QuestionWithButtons from '@/components/common/QuestionWithButtons.vue'
  import useSegment from '@/composables/useSegment'
  import useBreakpoint from '@/composables/useBreakpoint'
  import useGamification from '@/composables/useGamification'
  import GuidedQuizResults from '@/components/course/GuidedQuizResults.vue'
  import BarChart from '@/components/common/BarChart.vue'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { GuidedQuizCompletionAbstractResult, Question, QuestionAnswer } from '@/services/interfaces/QuizQuestion'

  const router = useRouter()
  const { currentCourseId, moduleId, course, studyingStatus, nextCourseModule, nextLesson } = useCourse()
  const { trackPage, pageViewed, guidedQuizQuestionAnswered, guidedQuizCompleted } = useSegment()
  const { trackGamificationActivity } = useGamification()
  const { convertNumberAnswer, shuffleArray, formatDate, getColorVariableValue, correctNumberAnswer } = useCommonMixin()
  const { translateString } = useI18n()
  const { arrowRight } = useIcons()
  const { isMobileDevice, isDesktopDevice } = useBreakpoint()

  const fetchingQuizCompletion = ref(true)
  const questionIndex = ref(0)
  const wrongQuestions = ref([] as Question[])
  const selectedAnswers = ref([] as QuestionAnswer[])
  const startedQuiz = ref(false)
  const calculatedPoints = ref(false)
  const completedGuidedQuiz = ref(false)
  const postingQuizAnswer = ref(false)
  const redoingWrongAnswers = ref(false)
  const guidedQuizCompletions = ref([] as GuidedQuizCompletionAbstractResult[])

  const loadingGuidedQuiz = computed(() => fetchingQuizCompletion.value || currentModule.value === null)

  const currentModule = computed(() => CourseModule.currentModule(currentCourseId.value, moduleId.value))

  const guidedQuiz = computed(() => currentModule.value?.guidedQuiz)

  const currentGuidedQuizCompletionId = computed(() => guidedQuiz.value?.latestGuidedQuizCompletionId ?? null)

  const actualQuestions = computed(() => latestCompletionQuestions.value)

  const currentQuestion = computed(() => {
    if (redoingWrongAnswers.value) return wrongQuestions.value[questionIndex.value]
    return actualQuestions.value[questionIndex.value]
  })

  const currentCorrectAnswers = computed(() => currentQuestion.value?.answers.filter((ans) => ans.isCorrect))

  const points = reactive({
    correct: 0,
    wrong: 0,
  })

  const questionAnswered = computed(() => {
    return selectedAnswers.value.some((ans) => ans.questionId === currentQuestion.value?.questionId)
  })

  const isLastQuestion = computed(() => actualQuestions.value.length === questionIndex.value + 1)

  const getWrongQuestions = computed(() => {
    return actualQuestions.value.filter((question) => {
      const answeredQuestion = selectedAnswers.value.find((q) => q.questionId === question.questionId)
      const correctAnswers = question.answers.filter((a) => a.isCorrect)
      return !(
        answeredQuestion?.selectedAnswerIds.length === correctAnswers.length &&
        correctAnswers.every((ans) => {
          if (question.questionType === 'number') {
            return convertNumberAnswer(answeredQuestion.numberAnswer!) === ans.answer
          }
          return answeredQuestion.selectedAnswerIds.includes(ans.answerId)
        })
      )
    })
  })

  const totalQuestions = computed(() => {
    if (redoingWrongAnswers.value) return wrongQuestions.value?.length
    return actualQuestions.value.length
  })

  const hasNewQuestions = computed(() => {
    if (!guidedQuiz.value?.numberOfQuestions) return false
    return guidedQuiz.value.numberOfQuestions > totalQuestions.value
  })

  /*
   * BE randomizes questions from the module i.e. guidedQuiz.questions.
   * However, the questions it returns from a completion aren't randomized
   * So, what we want to do is to :
   *     1. Use questions from the module ONLY when the latestGuidedQuizCompletionId on the module is null
   *     2. Use questions from a completion when there's a valid latestGuidedQuizCompletionId
   */
  const guidedQuizCompletion = computed(() => CourseModule.guidedQuizCompletions[moduleId.value])
  const latestCompletionQuestions = computed(() => guidedQuizCompletion.value?.questions || [])

  const currentQuestionIndexInCompletion = computed(() => {
    return (questionId: string) => {
      return guidedQuizCompletion.value?.questions.findIndex((q) => q.questionId === questionId)
    }
  })

  const isAPurchasedCourse = computed(() => CourseModule.course?.purchased)

  const answeredQuestionCorrectly = computed(() => {
    return (selectedAnswerIds: string[], correctAnswers: string[]) => {
      return (
        selectedAnswerIds.every((answerId) => correctAnswers.includes(answerId)) &&
        selectedAnswerIds.length === correctAnswers.length
      )
    }
  })

  const completionsDateChartLabels = computed(() => {
    const chartLabels = guidedQuizCompletions.value.map((c) => formatDate(c.performedDate, 'dd LLL yy')).reverse()
    if (chartLabels.length < 5) {
      const daysToPrefill = 5 - chartLabels.length
      for (let i = 0; i < daysToPrefill; i++) {
        chartLabels.unshift('')
      }
    }
    return chartLabels
  })

  const completionsPassedPercentageChartData = computed(() => {
    if (guidedQuizCompletions.value.length === 0) return []
    const passedPercentage: number[] = []
    guidedQuizCompletions.value.forEach((completion) => {
      let totalAnsweredQuestions = 0
      let correctQuestions = 0

      completion.questions.forEach((q) => {
        let userAnswers = q.answers.filter((ans) => ans.selected)
        if (q.questionType === 'number' && q.userNumberAnswer) {
          totalAnsweredQuestions++
          userAnswers = [{ answerId: q.answers[0].answerId, answer: q.userNumberAnswer }]
        } else if (q.questionType === 'single' || q.questionType === 'multiple') {
          if (q.answers.some((a) => a.selected)) {
            totalAnsweredQuestions++
          }
        }

        const correctAnswers = q.answers.filter((a) => a.isCorrect)
        const isCorrectNumberQuestion =
          q.questionType === 'number' ? correctNumberAnswer(q.userNumberAnswer ?? '', q.answers[0].answer) : true
        if (
          answeredQuestionCorrectly.value(
            userAnswers.map((a) => a.answerId),
            correctAnswers.map((a) => a.answerId),
          ) &&
          isCorrectNumberQuestion
        ) {
          correctQuestions++
        }
      })
      passedPercentage.push(Math.round((correctQuestions / totalAnsweredQuestions) * 100))
    })
    if (passedPercentage.length < 5) {
      const daysToPrefill = 5 - passedPercentage.length
      for (let i = 0; i < daysToPrefill; i++) {
        passedPercentage.push(0)
      }
    }
    return passedPercentage.reverse()
  })

  const chartBackgroundColors = computed(() => {
    const colors: string[] = new Array(completionsPassedPercentageChartData.value.length)
    colors.fill(
      getColorVariableValue.value('--secondary-color'),
      0,
      completionsPassedPercentageChartData.value.length - 1,
    )
    colors.fill(getColorVariableValue.value('--primary-color'), completionsPassedPercentageChartData.value.length - 1)
    return colors
  })

  const completionsChartData = computed(() => {
    return {
      labels: completionsDateChartLabels.value,
      datasets: [
        {
          label: translateString('courses.guidedQuizCompletionsChart.quizPassedPercentage'),
          backgroundColor: chartBackgroundColors.value,
          data: completionsPassedPercentageChartData.value,
        },
      ],
    }
  })

  const chartLabels = computed(() => {
    return [
      {
        color: 'var(--secondary-color)',
        label: translateString('courses.guidedQuizCompletionsChart.previouslyPassedPercentage'),
        show: guidedQuizCompletions.value.length > 1,
      },
      {
        color: 'var(--primary-color)',
        label: translateString('courses.guidedQuizCompletionsChart.latestPassedPercentage'),
        show: true,
      },
    ]
  })

  const nextQuestion = async (answers: QuestionAnswer[]) => {
    if (questionAnswered.value) {
      calculatedPoints.value = true
    } else {
      selectedAnswers.value.push(...answers)
    }

    if (calculatedPoints.value) {
      await handleCalculatedPoints()
      return
    }

    const choice = selectedAnswers.value.find((ans) => ans.questionId === currentQuestion.value!.questionId)
    await handleUnansweredQuestion(choice)
    await handleAnswerQuestion(choice)
  }

  const handleCalculatedPoints = async () => {
    if (
      !actualQuestions.value[questionIndex.value + 1] ||
      (redoingWrongAnswers.value && !wrongQuestions.value[questionIndex.value + 1])
    ) {
      completeGuidedQuiz()
      await handleGuidedQuizCompletion()
      fetchAllGuidedQuizCompletions()
    } else {
      questionIndex.value++
      if (!questionAnswered.value) {
        calculatedPoints.value = false
      }
      eventBus.$emit('reset-background-tile-height')
    }
  }

  const completeGuidedQuiz = () => {
    completedGuidedQuiz.value = true
    trackGamificationActivity('GUIDED_QUIZ_COMPLETED', {
      courseId: currentCourseId.value,
      moduleId: moduleId.value,
      lessonId: null,
    })
  }

  const handleGuidedQuizCompletion = async () => {
    if (currentGuidedQuizCompletionId.value) {
      await guidedQuizCompleted(
        guidedQuiz.value!.id!,
        currentGuidedQuizCompletionId.value,
        currentCourseId.value,
        course.value!.title,
        moduleId.value,
        currentModule.value?.name || '',
      )
    }
  }

  const handleUnansweredQuestion = async (choice: QuestionAnswer | undefined) => {
    postingQuizAnswer.value = true

    const resp = await CourseApi.postGuidedQuizAnswer(currentCourseId.value, moduleId.value, {
      completionId: currentGuidedQuizCompletionId.value,
      questionId: currentQuestion.value!.questionId,
      selectedAnswerIds: choice!.selectedAnswerIds,
      numberAnswer: convertNumberAnswer(choice?.numberAnswer || ''),
      state: isLastQuestion.value ? 'completed' : '',
    })

    CourseModule.setLatestGuidedQuizCompletionId({
      courseId: currentCourseId.value,
      moduleId: moduleId.value,
      completionId: resp.completionId,
    })

    updateCurrentQuestionInCompletion(
      currentQuestion.value?.questionId,
      currentQuestion.value?.questionType,
      choice!.selectedAnswerIds,
    )
    postingQuizAnswer.value = false
  }

  const handleAnswerQuestion = async (choice: QuestionAnswer | undefined) => {
    let questionAnsweredCorrectly: boolean
    if (currentQuestion.value.questionType === 'number') {
      const numberAnswer = convertNumberAnswer(choice?.numberAnswer ?? '')
      if (correctNumberAnswer(numberAnswer, currentCorrectAnswers.value[0].answer)) {
        points.correct++
        trackGamificationActivity('GUIDED_QUIZ_QUESTION_ANSWERED_CORRECTLY', {
          courseId: currentCourseId.value,
          moduleId: moduleId.value,
          lessonId: null,
        })
        questionAnsweredCorrectly = true
      } else {
        points.wrong++
        questionAnsweredCorrectly = false
      }
    } else {
      if (
        currentCorrectAnswers.value?.length === choice?.selectedAnswerIds.length &&
        currentCorrectAnswers.value?.every((answer) => choice?.selectedAnswerIds.includes(answer.answerId))
      ) {
        points.correct++
        trackGamificationActivity('GUIDED_QUIZ_QUESTION_ANSWERED_CORRECTLY', {
          courseId: currentCourseId.value,
          moduleId: moduleId.value,
          lessonId: null,
        })
        questionAnsweredCorrectly = true
      } else {
        points.wrong++
        questionAnsweredCorrectly = false
      }
    }
    calculatedPoints.value = true
    await guidedQuizQuestionAnswered(
      currentCourseId.value,
      course.value!.title,
      moduleId.value,
      currentModule.value?.name || '',
      currentQuestion.value!.questionId,
      questionIndex.value + 1,
      totalQuestions.value,
      questionAnsweredCorrectly,
    )
  }

  const previousQuestion = () => {
    calculatedPoints.value = true
    questionIndex.value--
  }

  const abortQuiz = () => {
    startedQuiz.value = false
    resetQuiz()
    CourseModule.getGuidedQuizCompletion({ courseId: currentCourseId.value, moduleId: moduleId.value })
  }

  const redoWrongAnswers = () => {
    questionIndex.value = 0
    completedGuidedQuiz.value = false
    calculatedPoints.value = false
    points.wrong = 0
    redoingWrongAnswers.value = true
    wrongQuestions.value = getWrongQuestions.value || []
    wrongQuestions.value.forEach((q) => {
      const index = selectedAnswers.value.findIndex((ans) => ans.questionId === q.questionId)
      if (index !== -1) {
        selectedAnswers.value.splice(index, 1)
      }
      q.answers.forEach((a) => (a.selected = false))
    })
  }

  const redoWholeTest = async () => {
    fetchingQuizCompletion.value = true
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    CourseModule.setGuidedQuizCompletion({ [moduleId.value]: null })
    await CourseModule.getGuidedQuizCompletion({ courseId: currentCourseId.value, moduleId: moduleId.value }).finally(
      () => {
        if (guidedQuizCompletion.value?.completionId) {
          CourseModule.setLatestGuidedQuizCompletionId({
            courseId: currentCourseId.value,
            moduleId: moduleId.value,
            completionId: guidedQuizCompletion.value.completionId,
          })
        }
        fetchingQuizCompletion.value = false
      },
    )
    resetQuiz()
  }

  const resetQuiz = () => {
    selectedAnswers.value = []
    questionIndex.value = 0
    completedGuidedQuiz.value = false
    calculatedPoints.value = false
    points.wrong = 0
    points.correct = 0
    redoingWrongAnswers.value = false
    eventBus.$emit('reset-background-tile-height')
  }

  const nextLessonOrModule = () => {
    const params: { [key: string]: string } = {}
    const mod = nextCourseModule()
    const lesson = nextLesson()
    if (mod) params.moduleId = mod.id
    if (lesson) params.lessonId = params.lessonId = lesson.id

    router
      .push({
        name: lesson ? 'Lesson' : 'CourseModule',
        params,
      })
      .catch(() => {
        return
      })
  }

  const updateCurrentQuestionInCompletion = (
    questionId: string,
    questionType: 'single' | 'multiple' | 'number',
    selectedAnsIds: string[],
  ) => {
    selectedAnsIds.forEach((ansId) => {
      if (guidedQuizCompletion.value) {
        const currentQuestionInCompletion =
          guidedQuizCompletion.value.questions[currentQuestionIndexInCompletion.value(questionId)!]
        if (questionType === 'number') {
          currentQuestionInCompletion.userNumberAnswer = ansId
        } else {
          currentQuestionInCompletion?.answers.forEach((ans) => {
            if (ans.answerId === ansId) {
              ans.selected = true
            }
          })
        }
      }
    })
  }

  const fetchAllGuidedQuizCompletions = () => {
    CourseApi.getGuidedQuizCompletions(currentCourseId.value, moduleId.value).then((completions) => {
      guidedQuizCompletions.value = completions
    })
  }

  const fetchSpecificGuidedQuizCompletion = (completionId: string) => {
    CourseApi.getSpecificGuidedQuizCompletion(currentCourseId.value, moduleId.value, completionId)
      .then((data) => {
        if (data[0] && data[0].questions) {
          const { questions } = data[0]
          CourseModule.setGuidedQuizCompletion({ [moduleId.value]: data[0] })
          questions.forEach((question) => {
            let userAnswers = question.answers.filter((ans) => ans.selected)
            if (question.questionType === 'number' && question.userNumberAnswer) {
              userAnswers = [{ answerId: question.answers[0].answerId, answer: question.userNumberAnswer }]
            }
            if (userAnswers.length) {
              selectedAnswers.value.push({
                questionId: question.questionId,
                selectedAnswerIds: userAnswers.map((ans) => ans.answerId),
                numberAnswer: question.userNumberAnswer,
              })
              questionIndex.value++
              const correctAnswers = question.answers.filter((a) => a.isCorrect)
              const isCorrectNumberQuestion =
                question.questionType === 'number'
                  ? correctNumberAnswer(question.userNumberAnswer ?? '', question.answers[0].answer)
                  : true
              if (
                answeredQuestionCorrectly.value(
                  userAnswers.map((a) => a.answerId),
                  correctAnswers.map((a) => a.answerId),
                ) &&
                isCorrectNumberQuestion
              ) {
                points.correct++
              } else points.wrong++
            }
          })
          if (selectedAnswers.value.length) startedQuiz.value = true
          if (selectedAnswers.value.length === questions.length) {
            completedGuidedQuiz.value = true
          }
        }
      })
      .finally(() => {
        fetchingQuizCompletion.value = false
      })
  }

  const getGuidedQuizCompletion = () => {
    if (currentGuidedQuizCompletionId.value) {
      fetchSpecificGuidedQuizCompletion(currentGuidedQuizCompletionId.value)
    } else {
      CourseModule.getGuidedQuizCompletion({ courseId: currentCourseId.value, moduleId: moduleId.value })
        .then(() => {
          if (guidedQuizCompletion.value) {
            CourseModule.setLatestGuidedQuizCompletionId({
              courseId: currentCourseId.value,
              moduleId: moduleId.value,
              completionId: guidedQuizCompletion.value.completionId,
            })
          }
        })
        .finally(() => (fetchingQuizCompletion.value = false))
    }
  }

  watch(currentQuestion, () => {
    if (!questionAnswered.value && currentQuestion.value) {
      if (currentQuestion.value.answers) {
        if (guidedQuizCompletion.value && !redoingWrongAnswers.value) {
          guidedQuizCompletion.value.questions[
            currentQuestionIndexInCompletion.value(currentQuestion.value.questionId)!
          ].answers = shuffleArray(currentQuestion.value.answers)
        }
        currentQuestion.value.answers = currentQuestion.value.answers.map((a) => ({
          ...a,
          selected: false,
        }))
      }
    }
  })

  const handleGuidedQuizLifecycle = () => {
    trackPage('Guided Quiz')
    pageViewed('Guided Quiz')
    getGuidedQuizCompletion()
    fetchAllGuidedQuizCompletions()
  }

  watch(moduleId, () => {
    // user could be moving from one guided quiz to another
    startedQuiz.value = false
    fetchingQuizCompletion.value = true
    resetQuiz()
    handleGuidedQuizLifecycle()
  })

  onMounted(() => {
    handleGuidedQuizLifecycle()
  })
</script>

<style lang="postcss">
  .guided-quiz {
    .loading {
      @apply ketch-my-c80;
    }
    .header-info {
      @apply md:ketch-max-w-[550px] ketch-bg-black ketch-text-white md:ketch-bg-transparent;
      @apply md:ketch-text-primary-text-color ketch--mx-c16 sm:ketch--mx-c20 md:ketch-mx-0;
      @apply ketch-px-c16 sm:ketch-px-c20 md:ketch-px-0 ketch--mt-c15 md:ketch-mt-0 ketch-py-c10 md:ketch-py-0;
      @apply ketch-mb-c30 sm:ketch-mb-c50 md:ketch-mb-c100;
      @apply ketch-sticky ketch-top-c30 ketch-z-10 md:ketch-top-auto md:ketch-relative;
      .description {
        @apply ketch-text-white ketch-text-opacity-80 md:ketch-text-primary-text-color md:ketch-text-opacity-100;
      }
    }
    .quiz-content {
      @apply ketch-pb-c40 md:ketch-pb-0;
      .question-with-buttons {
        @apply ketch-max-w-full;
        .question-content {
          @apply ketch-flex ketch-space-x-c30;
        }
        .buttons-container,
        .question {
          @apply md:ketch-max-w-[calc(100%-200px)];
        }
        button {
          @apply ketch-min-w-[unset];
        }
        .results-cta {
          @apply ketch-flex ketch-flex-col ketch-space-y-c5 ketch-w-[170px];
          button {
            @apply ketch-w-auto ketch-max-w-[150px];
          }
        }
      }
    }
    .quiz-results {
      h3 {
        @apply ketch-font-bold;
      }
      .quiz-results-wrapper {
        @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-mt-c25 ketch-pb-c40 ketch-border-b;
        @apply ketch-border-border-color ketch-mb-c50;
        .buttons-results {
          @apply ketch-flex ketch-flex-col ketch-space-y-c35 ketch-items-end;
          .buttons {
            @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-w-full;
            @apply md:ketch-flex-row md:ketch-items-center md:ketch-space-x-c20 md:ketch-space-y-0;
            button {
              @apply ketch-w-max;
            }
          }
        }
        .quiz-results-completions {
          @apply ketch-flex ketch-flex-col ketch-items-center ketch-space-y-c10;
          .chart-labels {
            @apply ketch-flex ketch-flex-col ketch-space-y-c5;
            @apply md:ketch-items-center md:ketch-flex-row md:ketch-space-x-c20 md:ketch-space-y-0;
            .chart-label {
              @apply ketch-flex ketch-items-center ketch-space-x-c10;
              .chart-color {
                @apply ketch-w-[40px] ketch-h-[10px];
              }
            }
          }
          .bar-chart-wrapper {
            @apply ketch-w-full;
            #bar-chart {
              @apply ketch-max-h-[320px];
            }
          }
        }
      }
      .button-wrapper {
        @apply ketch-flex ketch-justify-end;
        button {
          @apply ketch-flex ketch-space-x-c15;
        }
      }
    }
    .quiz-content .guided-quiz-results,
    .quiz-results .guided-quiz-results,
    .header-info .guided-quiz-results {
      @apply ketch-flex ketch-flex-col ketch-border ketch-border-secondary-hover-color;
      @apply ketch-rounded-normal ketch-bg-secondary-hover-color ketch-bg-opacity-[0.04];
      @apply ketch-overflow-hidden ketch-h-[fit-content] ketch-max-w-[150px] ketch-mt-c120;
      > .correct,
      > .wrong {
        @apply ketch-flex ketch-items-center ketch-space-x-c10 ketch-h-c30 ketch-py-c10 ketch-px-c15;
        h5 {
          @apply ketch-font-bold ketch-space-x-c5;
        }
      }
      > .correct {
        @apply ketch-border-b ketch-border-secondary-hover-color ketch-text-primary-text-color;
      }
    }
    .header-info .guided-quiz-results {
      @apply ketch-flex-row ketch-border-none ketch-max-w-[unset] ketch-bg-transparent ketch-mt-0;
      > .correct {
        @apply ketch-border-none ketch-pl-0;
      }
      h5 {
        @apply ketch-text-white;
      }
    }
  }
</style>
