<template lang="pug">
.course-tile(
    :class='{ hide: showHideBackground, promoted: course.promoted, editing: inEditorMode, "in-progress": course.state === "in_progress", locked: course.locked || !canAccessKnowledgeCheckCourse, "not-editing": !inEditorMode }',
    @click='openCourseAction(false)',
    @mouseleave='hovering = false',
    @mouseover='hovering = true',
    data-cy='course-tile',
    ref='el',
    v-tooltip.top='{ content: $t("editor.visibleToEditors"), show: showIsProgressTooltip && hovering, trigger: "focus" }'
)
    .course-unlocked(
        data-cy='payment-indicator',
        v-if='course.purchased'
    )
        SVGRenderer(
            :has-hover='false',
            :icon='unlockIcon',
            fill-color='var(--secondary-foreground-color)',
            width='16'
        )
    .course-tile-wrapper
        .bg-hide(
            @click.stop,
            v-if='showHideBackground'
        )
        .editor-elements-wrapper(
            @click.stop,
            v-if='inEditorMode'
        )
            .editor-elements
                .drag-details-page
                    Drag(
                        @click.native='$emit("clicked-drag")',
                        v-if='isDraggable'
                    )
                    .details-page(@click.prevent.stop='openCourseAction(true)')
                        SVGRenderer(
                            :has-hover='false',
                            :icon='openCourseIcon',
                            fill-color='var(--primary-text-color)'
                        )
                h6.in-progress(v-if='course.state === "in_progress"') {{ $t('inProgress') }}
                .hide-promote(v-else-if='sectionId !== "unassigned-section-id"')
                    .promote(@click.prevent.stop='promoteCourse')
                        SVGRenderer(
                            :fill-color='course.promoted ? "var(--primary-color)" : ""',
                            :has-hover='false',
                            :icon='starIcon',
                            stroke-color='var(--primary-color)'
                        )
                    h6(@click.prevent.stop='hideCourse') {{ course.visibleToCourseSection ? $t('hide') : $t('show') }}
            .course-group
                h6.groups-cta
                    span(v-if='!visibleGroupsLength') ⚠️
                    span {{ visibleForXGroups }}
                h6.change(@click.stop='showAssignCourseGroupModal') {{ $t('change') }}
        img.card-image(:src='course.cardTeaserImageUrl || cardEmptyImage')
        .tags-title
            h6.tags(v-if='isDesktopDevice || (isMobileDevice && courseTags)') {{ courseTags }}
            h4.course-tile-title(
                :style='{ height: computedHeight }',
                ref='courseTitleRef'
            ) {{ course.title }}
        .course-description-text
            HTMLRenderer.h5(
                :html='course.teaserDescription',
                link-target='_blank'
            )
        .meta-info(:class='{ locked: course.locked || !canAccessKnowledgeCheckCourse }')
            SVGRenderer(
                :has-hover='false',
                :icon='lockIcon',
                stroke-color='var(--primary-color)',
                v-if='course.locked || !canAccessKnowledgeCheckCourse'
            )
            template(v-else-if='!isKnowledgeCheckCompany')
                .lessons-duration
                    h6.lessons {{ $t('trainings.numberOfLessons', { number: course.numberOfLessons }) }}
                    h6.duration {{ courseDuration }}h {{ $t('content') }}
                h6.progress(v-if='course.purchased || course.expired') {{ course.progressPercentage || 0 }}%
                h6.in-trial(v-else-if='course.trial') {{ $t('courses.inTest') }}
                h6.is-free(v-else-if='course.free') {{ $t('trainings.free') }}
                h6.price(v-else-if='showCoursePrice') {{ currencyFormatDE(productPrice(course)) }}
        ProgressBar(
            :percentage='course.progressPercentage || 0',
            v-if='!course.locked && !isKnowledgeCheckCompany && (course.purchased || course.trial || course.expired)'
        )
</template>

<script setup lang="ts">
  import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import HTMLRenderer from '@/components/common/HTMLRenderer.vue'
  import { CourseModule } from '@/store/modules/course'
  import useCourse from '@/composables/useCourse'
  import useDuration from '@/composables/useDuration'
  import ProgressBar from '@/components/common/ProgressBar.vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import Drag from '@/components/common/Drag.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useEditor from '@/composables/useEditor'
  import { EditorModule } from '@/store/modules/editor'
  import useSegment from '@/composables/useSegment'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { UserModule } from '@/store/modules/user'
  import { useRouter } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { CourseItem } from '@/services/interfaces/Course'

  const props = defineProps({
    course: {
      required: true,
      type: Object as PropType<CourseItem>,
    },
    sectionId: {
      required: true,
      type: String,
    },
    maxHeight: {
      type: Number,
      default: 0,
    },
    hideBackground: {
      type: Boolean,
      default: false,
    },
    isDraggable: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['course-data', 'clicked-drag'])

  const router = useRouter()
  const { formatDurationToHHMM } = useDuration()
  const { guidedQuizDuration, canBuyCourses, courseGroupIds, productPrice } = useCourse()
  const { currencyFormatDE, isKnowledgeCheckCompany } = useCommonMixin()
  const { starIcon, cardEmptyImage, openCourseIcon, unlockIcon, lockIcon } = useIcons()
  const { inEditorMode: editMode } = useEditor()
  const { isLargeDesktop, isMobileDevice, isDesktopDevice, current } = useBreakpoint()
  const { courseItemClicked, courseUpsellModalShown } = useSegment()
  const { translateString } = useI18n()
  const el = ref(null as HTMLDivElement | null)
  const hovering = ref(false)

  const inEditorMode = computed(() => isLargeDesktop.value && editMode.value)

  const showHideBackground = computed(
    () => !props.course.visibleToCourseSection && props.sectionId !== 'unassigned-section-id' && !props.hideBackground,
  )

  const computedHeight = computed(() => (props.maxHeight ? props.maxHeight + 'px' : 'auto'))

  const courseDuration = computed(() => {
    const moduleDuration =
      CourseModule.coursesModuleMap[props.course.id]?.reduce((acc, mod) => {
        acc += guidedQuizDuration.value(mod.guidedQuiz?.numberOfQuestions || 0)
        return acc
      }, 0) || 0
    return formatDurationToHHMM((props.course.duration || 0) + moduleDuration)
  })

  const showCoursePrice = computed(() => productPrice.value(props.course) && canBuyCourses.value)

  const visibleGroupsLength = computed(() => courseGroupIds.value(props.course.id).length)

  const visibleForXGroups = computed(() =>
    translateString('trainings.visibleForXGroups', { groupsCount: visibleGroupsLength.value }),
  )

  const showIsProgressTooltip = computed(() => {
    return UserModule.isCompanyEditor && !inEditorMode.value && props.course.state === 'in_progress'
  })

  const canAccessKnowledgeCheckCourse = computed(() =>
    isKnowledgeCheckCompany.value ? isKnowledgeCheckCompany.value && props.course.knowledgeCheck : true,
  )

  const colSpan = computed(() => {
    if (['xxl', 'xxxl'].includes(current.value)) return 4
    if (['lg', 'xl'].includes(current.value)) return 3
    if (['sm', 'md'].includes(current.value)) return 2
    return 1
  })

  const courseTags = props.course.tags.map((t) => t.title).join(', ')

  const openCourseAction = (allowInEditMode: boolean) => {
    if (!allowInEditMode && inEditorMode.value) return
    if (isKnowledgeCheckCompany.value) {
      if (props.course.knowledgeCheck) {
        routeToKnowledgeCheckInfoOrCourseDetails('KnowledgeCheckInfo')
      }
      return
    }
    courseItemClicked(props.course.id, props.course.title, props.course.state, 'trainings_page')
    if (props.course.state === 'ready' || UserModule.isCompanyEditor) {
      routeToKnowledgeCheckInfoOrCourseDetails('CourseDetails')
      eventBus.$emit('turn-off-loader')
    } else {
      courseUpsellModalShown(props.course.id, props.course.title, 'trainings_page')
      showUpsellModal()
    }
  }

  const routeToKnowledgeCheckInfoOrCourseDetails = (routeName: 'CourseDetails' | 'KnowledgeCheckInfo') => {
    router
      .push({
        name: routeName,
        params: { courseId: props.course.id },
      })
      .catch(() => {
        return
      })
  }

  const showUpsellModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'UpsellModal',
      cssClass: 'upsell',
      modalProps: {
        recommendedCourse: props.course,
      },
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const promoteCourse = () => {
    CourseModule.promoteSectionCourse({
      sectionId: props.sectionId,
      courseId: props.course.id,
      promoted: !props.course.promoted,
    }).then(() => {
      handleHidePromotedCallback('promoted')
    })
  }

  const hideCourse = () => {
    eventBus.$emit('turn-on-loader')
    CourseModule.hideSectionCourse({
      sectionId: props.sectionId,
      courseId: props.course.id,
      visible: !props.course.visibleToCourseSection,
    }).then(() => {
      handleHidePromotedCallback('visibleToCourseSection')
    })
  }

  const handleHidePromotedCallback = (prop: 'visibleToCourseSection' | 'promoted') => {
    const section = EditorModule.courseSections?.find((s) => s.id === props.sectionId)
    const course = section!.assignedCourses.find((c) => (c as CourseItem).id === props.course.id) as CourseItem
    course[prop] = !course[prop]
    eventBus.$emit('turn-off-loader')
  }

  const showAssignCourseGroupModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'AssignCourseGroupModal',
      modalProps: {
        course: props.course,
        groupIds: courseGroupIds.value(props.course.id),
        sectionId: props.sectionId,
      },
      cssClass: 'assign-course-group-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const courseTitleRef = ref(null as HTMLHeadingElement | null)
  const courseIndex = () => {
    if (!el.value) return -1
    if (!el.value.parentNode) return -1
    return [...el.value.parentNode!.children].filter((el) => el.classList.contains('eligible')).indexOf(el.value!)
  }

  const sendCourseData = () => {
    courseTitleRef.value?.style.setProperty('height', 'auto')
    nextTick(() => {
      emit('course-data', {
        sectionId: props.sectionId,
        courseId: props.course.id,
        rowNum: Math.ceil((courseIndex() + 1) / colSpan.value),
        titleHeight: courseTitleRef.value?.offsetHeight || 0,
        state: props.course.state,
      })
      courseTitleRef.value?.style.setProperty('height', computedHeight.value)
    })
  }

  const timerId = ref(0)
  watch(current, () => {
    if (timerId.value) window.clearTimeout(timerId.value)
    timerId.value = window.setTimeout(() => {
      sendCourseData()
    }, 1000)
  })

  onMounted(() => {
    //wait for animation to end
    window.setTimeout(() => {
      sendCourseData()
    }, 1000)
  })

  onBeforeUnmount(() => {
    // resetting courses that will be removed cos they are in progress when device orientation changes
    emit('course-data', {
      sectionId: props.sectionId,
      courseId: props.course.id,
      rowNum: Math.ceil((courseIndex() + 1) / colSpan.value),
      titleHeight: 0,
      state: props.course.state,
    })
  })
</script>

<style lang="postcss">
  .course-tile {
    @apply ketch-transition-transform ketch-ease-in-out ketch-duration-500 hover:md:ketch-scale-110;
    @apply ketch-relative ketch-cursor-pointer ketch-transform-none;
    &-wrapper {
      @apply ketch-relative ketch-z-[1] ketch-overflow-hidden ketch-rounded-normal ketch-p-c10 ketch-bg-white;
      @apply ketch-w-full xl:ketch-w-c250 ketch-grid ketch-grid-cols-[120px_1fr] md:ketch-grid-cols-1;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
    }
    &.locked {
      @apply ketch-pointer-events-none ketch-opacity-40;
    }
    &.editing {
      @apply ketch-cursor-default;
    }
    &.in-progress {
      &.not-editing {
        @apply ketch-opacity-60;
      }
      &:before {
        content: '';
        @apply ketch-absolute ketch-top-0 ketch-left-0 ketch-bottom-0 ketch-right-0 ketch-opacity-5;
        @apply ketch-bg-center ketch-bg-cover ketch-bg-opacity-5;
        background-image: url('../../../assets/images/card_in_progress_pattern.svg');
      }
    }
    &.hide {
      @apply ketch-relative ketch-scale-100 ketch-cursor-default;
      .bg-hide {
        @apply ketch-absolute ketch-left-0 ketch-right-0 ketch-top-0 ketch-bottom-0;
        @apply ketch-bg-white ketch-bg-opacity-80  ketch-z-[2];
      }
    }
    &.promoted {
      @apply ketch-border-2 ketch-border-primary-color;
    }
    .course-unlocked {
      @apply ketch-absolute ketch--right-c12 ketch--top-c12 ketch-bg-secondary-color ketch-w-c30 ketch-h-c30;
      @apply ketch-flex ketch-items-center ketch-justify-center ketch-rounded-full ketch-z-[2];
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      svg {
        @apply ketch-w-c16 ketch-h-c16;
      }
    }
    .editor-elements-wrapper {
      @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-mb-c10 ketch-relative ketch-z-[3];
      .editor-elements,
      .course-group {
        @apply ketch-flex ketch-items-center ketch-justify-between;
      }
      .editor-elements {
        .hide-promote {
          @apply ketch-space-x-c10 ketch-flex ketch-items-center;
          svg,
          h6 {
            @apply ketch-cursor-pointer;
          }
          h6 {
            @apply hover:ketch-underline;
          }
        }
        .drag-details-page {
          @apply ketch-flex ketch-items-center ketch-space-x-c10;
          svg {
            @apply ketch-cursor-pointer;
          }
        }
        .in-progress {
          @apply ketch-text-primary-color;
        }
      }
      .course-group {
        h6.groups-cta {
          @apply ketch-text-[11px];
        }
        h6 {
          @apply ketch-opacity-80;
          &.change {
            @apply hover:ketch-underline ketch-cursor-pointer ketch-lowercase;
          }
        }
      }
    }
    .card-image {
      @apply ketch-w-full ketch-object-cover ketch-rounded-[5px] ketch-row-start-1 ketch-row-end-4;
      @apply md:ketch-h-[205px] md:ketch-row-span-1 md:ketch-col-start-1 md:ketch-col-end-2 xl:ketch-h-[140px];
    }
    .tags-title {
      @apply ketch-row-span-1 ketch-col-start-2 ketch-col-end-3 md:ketch-row-span-2 md:ketch-col-start-1 md:ketch-col-end-2;
      @apply ketch-ml-c12 md:ketch-ml-0 ketch-inline-grid;
    }
    .tags {
      @apply ketch-text-[#0D0D0D] ketch-text-opacity-80 ketch-my-c5 ketch-h-c16;
      @apply ketch-whitespace-nowrap ketch-overflow-hidden ketch-overflow-ellipsis;
    }
    .course-tile-title {
      @apply ketch-font-bold ketch-whitespace-pre-wrap ketch-min-h-[22px] ketch-max-h-[66px] ketch-overflow-hidden;
      @apply ketch-text-xs ketch-leading-[18px] md:ketch-text-md md:ketch-leading-md;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
    .course-description-text {
      @apply ketch-relative ketch-mt-c10 ketch-row-start-4 ketch-row-end-[8] ketch-col-start-1 ketch-col-end-3;
      @apply md:ketch-row-span-4 md:ketch-col-start-1 md:ketch-col-end-2;
      .content-container {
        @apply ketch-overflow-hidden;
        display: -webkit-box;
        p {
          @apply ketch-overflow-hidden ketch-mb-0 ketch-pb-0 xs4:ketch-h-[72px] md:ketch-h-c80 ketch-text-[13px];
          @apply ketch-text-primary-text-color ketch-text-opacity-70 ketch-leading-[18px] md:ketch-leading-sm;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }
        p ~ * {
          display: none;
        }
      }
    }
    .meta-info {
      @apply ketch-flex ketch-items-center ketch-justify-between ketch-mt-c10 ketch-row-start-[9];
      @apply ketch-col-start-1 ketch-col-end-3 md:ketch-row-span-5 md:ketch-col-start-1 md:ketch-col-end-2;
      &.locked {
        @apply ketch-justify-end;
      }
      .lessons-duration {
        @apply ketch-flex ketch-items-center ketch-space-x-c15;
        h6 {
          @apply ketch-text-[#C9C9C9];
        }
      }
      .progress {
        @apply ketch-text-primary-color;
      }
      .is-free {
        @apply ketch-bg-primary-color ketch-text-primary-foreground-color ketch-px-[3px] ketch-py-c1;
      }
      .in-trial {
        @apply ketch-text-primary-color ketch-font-bold;
      }
      .price,
      .is-free,
      .progress {
        @apply ketch-font-bold;
      }
    }
    .progress-bar-wrapper {
      @apply ketch-w-full ketch-bg-[#E2E2E2] ketch-h-c4 ketch-border-0 ketch-rounded-none;
      @apply ketch-absolute ketch-bottom-0 ketch-left-0 ketch-right-0;
      .progress-bar {
        @apply ketch-bg-primary-color;
      }
    }
  }
  .tooltip {
    &.popover.editor-in-progress-popover {
      .popover-inner {
        @apply ketch-bg-editor-primary-color ketch-max-w-none;
        h5 {
          @apply ketch-text-editor-foreground-color;
        }
      }
      .popover-arrow {
        @apply ketch-border-editor-primary-color;
      }
    }
  }
</style>
