<template lang="pug">
.course-trial-selector-wrapper(:class='{ "showing-all-packages": showOtherBundles || !highlightedCourseBundlePrefix }')
    .course-trial-selector
        .selector-loader(v-if='loadingPackages')
            LoadingSpinner
        template(v-else-if='courseBundles && courseBundles.length >= 1')
            .selector-header
                .academy-logo(v-if='desktopLogo')
                    img(
                        :src='desktopLogo',
                        alt='logo'
                    )
                h2 {{ $t('onboarding.trialSelector.testPackages') }}
            .trial-packages
                .trial-package-wrapper(
                    :key='index',
                    v-for='(bundle, index) in courseBundles'
                )
                    .trial-package
                        .overall-info
                            .sector-info
                                h2.title {{ bundle.title }}
                                h5.job-title {{ bundle.jobTitle }}
                            TextRenderer.h5(
                                :source='bundle.teaserDescription',
                                skip-glossary-check,
                                v-if='isDesktopDevice && bundle.teaserDescription'
                            )
                            TextRenderer.h5(
                                :class='{ "bullet-points": bundleHasBenefits(bundle) }',
                                :source='stringifiedBundleBenefits(bundle.bundleBenefits)',
                                skip-glossary-check,
                                v-if='isDesktopDevice && bundle.bundleBenefits'
                            )
                            .select-complete-package(
                                @click='selectCompletePackage(bundle.courses)',
                                v-if='isDesktopDevice && !highlightedCourseBundlePrefix'
                            )
                                h5 {{ $t('onboarding.trialSelector.selectCompletePackage') }}
                                .status
                                    SVGRenderer(
                                        :has-hover='false',
                                        :icon='checkmark',
                                        :stroke-color='"#008A40"',
                                        height='10px',
                                        v-if='packageIsSelected(bundle.courses)',
                                        width='10px'
                                    )
                                    SVGRenderer(
                                        :has-hover='false',
                                        :icon='arrowAltRight',
                                        :stroke-color='"#00254A"',
                                        height='10px',
                                        v-else,
                                        width='15px'
                                    )
                        .courses-wrapper
                            .courses
                                .course(
                                    :class='{ selected: courseIsSelected(course.id), "not-ready": course.state !== "ready" }',
                                    :key='course.id',
                                    @click='toggleSelectCourse(course)',
                                    v-for='course in bundle.courses'
                                )
                                    template(v-if='isDesktopDevice')
                                        PlaceholderShimmer(:animate='true')
                                            template(v-slot:default='{ isLoadingResource, onResourceLoaded }')
                                                img.teaser-image(
                                                    :class='{ "shimmer-asset": isLoadingResource }',
                                                    :src='course.cardTeaserImageUrl',
                                                    @load='onResourceLoaded'
                                                )
                                        .select-section
                                            img(
                                                :src='checkedCheckboxSquare',
                                                v-if='courseIsSelected(course.id)'
                                            )
                                            img(
                                                :src='checkboxLight',
                                                v-else
                                            )
                                            h6 {{ $t('onboarding.trialSelector.activateFreeTrial') }}
                                    .mobile-course-wrapper(v-else)
                                        img(
                                            :src='checkedCheckboxSquare',
                                            v-if='courseIsSelected(course.id)'
                                        )
                                        img(
                                            :src='checkboxLight',
                                            v-else
                                        )
                                        h6 {{ course.shortTitle }}
                            .new-signup-flow-submit-cta(v-if='highlightedCourseBundlePrefix && !showOtherBundles')
                                KetchUpButton(
                                    :class='{ disabled: !anyCourseInBundleIsSelected(bundle.courses) }',
                                    @click.native='submitTrials',
                                    data-cy='submitTrials'
                                )
                                    LoadingSpinner(v-if='submittingTrials')
                                    h5(v-else) {{ $t('onboarding.trialSelector.startPreparing') }}
                    .zigzag(v-if='index < courseBundles.length - 1')
                        SVGRenderer(
                            :has-hover='false',
                            :icon='zigZagIcon',
                            :stroke-color='"var(--border-color)"'
                        )
            .choose-other-packages(
                @click='showOtherBundles = !showOtherBundles',
                v-if='highlightedCourseBundlePrefix'
            )
                h5 {{ showMoreOrLessPackagesText }}
                SVGRenderer(
                    :class='{ open: showOtherBundles }',
                    :has-hover='false',
                    :icon='arrowDown',
                    :stroke-color='"#00254A"',
                    height='15px',
                    width='15px'
                )
            .selector-footer(v-if='showOtherBundles || !highlightedCourseBundlePrefix')
                .footer-cta-wrapper
                    .footer-cta
                        h5(v-if='!selectedCourses.length') {{ $t('onboarding.trialSelector.pleaseSelectAtLeastOne') }}
                        .new-signup-flow-submit-cta
                            KetchUpButton(
                                :class='{ disabled: !selectedCourses.length }',
                                @click.native='submitTrials',
                                data-cy='submitTrials'
                            )
                                LoadingSpinner(v-if='submittingTrials')
                                h5(v-else) {{ $t('onboarding.trialSelector.startPreparing') }}
        template(v-else)
            .no-trial-packages
                h2 {{ $t('onboarding.trialSelector.noPackagesCurrently') }}
                KetchUpButton.primary(@click.native='goToNextOnboardingPage')
                    h5 {{ $t('proceed') }}
</template>

<script setup lang="ts">
  import { computed, onMounted, ref, watch } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import PlaceholderShimmer from '@/components/common/PlaceholderShimmer.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import useBreakpoint from '@/composables/useBreakpoint'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import useCommonMixin from '@/composables/useCommonMixin'
  import { UserModule } from '@/store/modules/user'
  import useSegment from '@/composables/useSegment'
  import { useRoute, useRouter } from 'vue-router/composables'
  import type { CourseItem, CoursePackage } from '@/services/interfaces/Course'
  import TextRenderer from '@/components/editor/TextRenderer.vue'
  import useI18n from '@/composables/useI18n'
  import CookieHelper from '@/helpers/CookieHelper'

  const route = useRoute()
  const router = useRouter()
  const { zigZagIcon, checkboxLight, checkedCheckboxSquare, checkmark, arrowAltRight, arrowDown } = useIcons()
  const { isDesktopDevice } = useBreakpoint()
  const {
    nextOnboardingPage,
    isDevCompany,
    joinArrayOfStringsWithNewlineCharacter,
    setIsLoadingComponentViewData,
    highlightedCourseBundlePrefix,
    desktopLogo,
  } = useCommonMixin()
  const { pageViewed, trackPage, frontendCourseTrialStarted } = useSegment()
  const { translateString } = useI18n()

  const submittingTrials = ref(false)
  const loadingPackages = ref(true)
  const showOtherBundles = ref(true)

  const selectedCourses = ref([] as { courseId: string; courseName: string }[])

  // we are hardcoding the order for now
  const preferredOrderedCourseBundles = [
    {
      id: '81d1fa5a-4be3-434c-bf2d-f50030c6c5c5',
      courses: ['63d7d5bb-e8e9-429d-b360-cefdecd80448', '489dd660-9a78-4f94-8076-1e8f20a451e2'],
    },
    {
      id: 'cd8018c0-ab72-4d94-a3e5-001a498f1640',
      courses: ['8de44221-a055-4cdb-8ce5-d7ac148cb49e', 'ef7607dd-bb67-42f7-b0f8-a0f1a437fdd9'],
    },
    {
      id: '73e5ef94-2e97-470f-b445-6c885dce7baa',
      courses: ['84be496a-b904-4184-b456-98736fea9259', '284424aa-1a0e-4c60-a5e0-d8df6d9d1868'],
    },
    {
      id: '60dde532-73c3-4b11-8c6f-13f1bba92c1c',
      courses: ['4dc07be7-7aab-43a0-ae70-4b5f00b5a79e', 'd1643bae-db0b-4778-9662-7080f21ea5e4'],
    },
  ]

  const filterBundles = (bundles: CoursePackage[]) => {
    return bundles.filter((bundle) => bundle.courseBundleId !== '31aa50e8-1c38-47d6-a487-d94507a9dbf2')
  }

  const orderCourses = (bundle: CoursePackage, preferredBundle: { id: string; courses: string[] }) => {
    bundle.courses.sort((a, b) => {
      return preferredBundle.courses.indexOf(a.id) - preferredBundle.courses.indexOf(b.id)
    })
  }

  const getOrderedBundles = (filteredBundles: CoursePackage[]) => {
    return preferredOrderedCourseBundles.map((preferredBundle) => {
      const actualBundle = filteredBundles.find((bundle) => bundle.courseBundleId === preferredBundle.id)
      if (actualBundle) {
        orderCourses(actualBundle, preferredBundle)
      }
      return actualBundle
    })
  }

  const sortBundlesByPrefix = (bundles: CoursePackage[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return bundles.sort((a, b) => {
      if (a.title.includes(highlightedCourseBundlePrefix.value!)) return -1
      return 1
    })
  }

  const courseBundles = computed(() => {
    if (isDevCompany.value) return CourseModule.courseBundles
    if (!CourseModule.courseBundles) return []

    const filteredBundles = filterBundles(CourseModule.courseBundles)
    const preOrderedBundles = getOrderedBundles(filteredBundles)

    if (!highlightedCourseBundlePrefix.value) return preOrderedBundles

    let sortedBasedOnPrefix: CoursePackage[] = []

    if (preOrderedBundles && preOrderedBundles.length) {
      sortedBasedOnPrefix = sortBundlesByPrefix(preOrderedBundles as CoursePackage[])
    }

    return showOtherBundles.value ? sortedBasedOnPrefix : [sortedBasedOnPrefix[0]]
  })

  const stringifiedBundleBenefits = computed(() => (bundleBenefits: string[]) => {
    return joinArrayOfStringsWithNewlineCharacter(bundleBenefits)
  })

  const courseIsSelected = computed(() => {
    return (courseId: string) => {
      return selectedCourses.value.some((c) => c.courseId === courseId)
    }
  })

  const anyCourseInBundleIsSelected = computed(
    () => (courses: CourseItem[]) => courses.some((course) => courseIsSelected.value(course.id)),
  )

  const packageIsSelected = computed(() => {
    return (courses: CourseItem[]) => {
      return courses?.every((course) => {
        return courseIsSelected.value(course.id)
      })
    }
  })

  const bundleHasBenefits = computed(
    () => (bundle: CoursePackage) => !!bundle.bundleBenefits && bundle.bundleBenefits.length,
  )

  const showMoreOrLessPackagesText = computed(() =>
    showOtherBundles.value
      ? translateString('onboarding.trialSelector.showLessPackages')
      : translateString('onboarding.trialSelector.chooseOtherPackages'),
  )

  const toggleSelectCourse = (course: CourseItem) => {
    if (!course) return
    if (course.state !== 'ready') return

    const isSelected = courseIsSelected.value(course.id)

    isSelected ? unselectCourse(course) : selectCourse(course)
  }

  const selectCourse = (course: CourseItem) => {
    selectedCourses.value.push({ courseId: course.id, courseName: course.title })
  }

  const unselectCourse = (course: CourseItem) => {
    const courseIndex = selectedCourses.value.findIndex((c) => c.courseId === course.id)

    if (courseIndex !== -1) {
      selectedCourses.value.splice(courseIndex, 1)
    }
  }

  const selectCompletePackage = (courses: CourseItem[]) => {
    const allAreSelected = courses.every((course) => courseIsSelected.value(course.id))

    courses.forEach((course) => {
      if (allAreSelected || !courseIsSelected.value(course.id)) {
        toggleSelectCourse(course)
      }
    })
  }

  const submitTrials = () => {
    submittingTrials.value = true
    const courseIds = selectedCourses.value.map((c) => c.courseId)
    if (!courseIds.length) return
    CourseApi.startCourseTrial(courseIds)
      .then(() => {
        CookieHelper.removeCrossDomainCookie('bundlePrefix')
        courseIds.forEach((id) => {
          const courseName = selectedCourses.value.find((s) => s.courseId === id)!.courseName
          frontendCourseTrialStarted(UserModule.email, id, courseName)
        })
      })
      .finally(() => {
        submittingTrials.value = false
        goToNextOnboardingPage()
      })
  }

  const goToNextOnboardingPage = () => {
    if (
      nextOnboardingPage.value('CourseTrialPage') &&
      nextOnboardingPage.value('CourseTrialPage') !== 'ExamDatePicker'
    ) {
      if (nextOnboardingPage.value('CourseTrialPage') !== route.name) {
        router.push({ name: nextOnboardingPage.value('CourseTrialPage') }).catch(() => {
          return
        })
      }
    } else {
      window.location.href = '/'
    }
  }

  const fetchPackages = () => {
    CourseModule.getPackageBundles().finally(() => {
      loadingPackages.value = false
    })
  }

  watch(loadingPackages, () => {
    const bundle = courseBundles.value?.[0]

    if (highlightedCourseBundlePrefix.value && bundle) {
      showOtherBundles.value = false

      bundle.courses?.forEach(toggleSelectCourse)
    }

    setIsLoadingComponentViewData(false)
  })

  onMounted(() => {
    fetchPackages()
    if (UserModule.hasValidUser) {
      if (UserModule.hasSelectedTrial) return goToNextOnboardingPage()
      trackPage('Course Trial Page')
      pageViewed('Course Trial Page')
    }

    setIsLoadingComponentViewData(false)
  })
</script>

<style lang="postcss">
  .course-trial-selector-wrapper {
    @apply ketch-flex ketch-justify-center;
    .course-trial-selector {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-text-[#00254A] ketch-w-full;
      @apply ketch-space-y-c30 ketch-py-c30 xs3:ketch-items-center;

      .selector-loader {
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-h-full;
      }

      .selector-header {
        @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center ketch-space-y-c15;

        .academy-logo {
          img {
            @apply ketch-max-h-[35px];
          }
        }

        h2 {
          @apply ketch-font-bold ketch-text-center;
        }
      }

      .trial-packages {
        @apply ketch-bg-white ketch-p-c20 ketch-rounded-3xl ketch-w-full;
        @apply xs3:ketch-max-w-[450px] md:ketch-max-w-box;

        .trial-package-wrapper {
          .trial-package {
            @apply ketch-flex ketch-flex-col ketch-space-y-c20;
            @apply md:ketch-flex-row md:ketch-justify-between md:ketch-space-x-c20 md:ketch-space-y-0;

            .overall-info {
              @apply ketch-flex ketch-flex-col ketch-relative ketch-basis-1/2;
              @apply md:ketch-space-y-c8 md:ketch-max-w-[370px];

              .sector-info {
                @apply ketch-text-center md:ketch-text-left;
                .title,
                .job-title {
                  @apply ketch-font-bold;
                }

                .job-title {
                  @apply ketch-text-primary-color;
                }
              }

              .bullet-points {
                .content-container {
                  * {
                    @apply ketch-pb-c5;
                  }

                  p,
                  ul {
                    @apply ketch--ml-c20;
                  }

                  p,
                  ul li,
                  ol li {
                    &:before {
                      @apply ketch-bg-[#008A40];
                      -webkit-mask: url('../assets/images/checkmark.svg') no-repeat 100% 100%;
                      mask: url('../assets/images/checkmark.svg') no-repeat 100% 100%;
                      content: '';
                      -webkit-mask-size: cover;
                      mask-size: cover;
                    }
                  }
                }
              }

              .select-complete-package {
                @apply ketch-absolute ketch-right-0 ketch-bottom-[20px];
                @apply ketch-flex ketch-items-center;

                .status {
                  @apply ketch-ml-c5 ketch-w-c15;
                }

                h5 {
                  @apply ketch-font-bold ketch-cursor-pointer;
                }
              }
            }

            .courses-wrapper {
              @apply ketch-flex ketch-flex-col ketch-space-y-c20 md:ketch-bg-[#F7F7F7];
              @apply md:ketch-p-c15 md:ketch-rounded-2xl ketch-items-center;
              .courses {
                @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-w-full;
                @apply md:ketch-flex-row md:ketch-space-x-c35 md:ketch-space-y-0;

                .course {
                  @apply md:ketch-max-w-[350px] ketch-border ketch-border-border-color ketch-rounded-normal;
                  @apply ketch-flex ketch-flex-col ketch-bg-white ketch-cursor-pointer md:ketch-rounded-large;

                  .teaser-image {
                    @apply ketch-rounded-large;
                  }

                  .select-section {
                    @apply ketch-px-c10 ketch-py-c20 ketch-flex ketch-items-center ketch-space-x-c10;
                    @apply ketch-border-t ketch-border-transparent;
                  }

                  &.not-ready {
                    @apply ketch-opacity-30 ketch-cursor-auto;
                  }

                  &.selected {
                    @apply ketch-border-[#008A40];
                    filter: drop-shadow(0px 0px 20px rgba(0, 138, 64, 0.35));

                    .select-section {
                      h5 {
                        @apply ketch-font-bold;
                      }
                    }
                  }

                  .mobile-course-wrapper {
                    @apply ketch-flex ketch-items-center ketch-space-x-c15 ketch-p-c10;
                  }
                }
              }
            }
          }

          .zigzag {
            @apply ketch-my-c20 md:ketch-my-c60 ketch-w-full ketch-overflow-hidden;
          }
        }
      }

      .choose-other-packages {
        @apply ketch-cursor-pointer ketch-flex ketch-items-center ketch-space-x-c10;
        svg {
          &.open {
            @apply ketch-rotate-180;
          }
        }
      }

      .selector-footer {
        @apply ketch-fixed ketch-bottom-0 ketch-bg-white ketch-w-full ketch-py-[18px] ketch-border-t ketch-border-border-color;
        @apply ketch-flex ketch-items-center ketch-justify-center ketch-right-0;
        @apply ketch-px-c16 sm:ketch-px-c20 md:ketch-px-c30 xl:ketch-px-c70;

        .footer-cta-wrapper {
          @apply ketch-max-w-box ketch-w-full;

          .footer-cta {
            @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-items-center;
            @apply md:ketch-flex-row md:ketch-justify-end md:ketch-space-x-c15 md:ketch-space-y-0;
          }
        }
      }

      .no-trial-packages {
        @apply ketch-flex ketch-flex-col ketch-justify-center ketch-items-center ketch-space-y-c20;
      }
    }

    &.showing-all-packages {
      .course-trial-selector {
        @apply ketch-pb-c100;
        .trial-packages {
          .trial-package-wrapper {
            .trial-package {
              .courses-wrapper {
                @apply ketch-bg-white md:ketch-bg-white ketch-p-0;

                .courses {
                  @apply ketch-bg-none;

                  .course {
                    @apply ketch-bg-[#F7F7F7];
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
